.addons-section {
  padding: 20px; /* Reduce padding to lessen space at the top */
  margin: 3px;
  background-color: #f9f9f9; /* Light background */
  border-radius: 6px; /* Rounded corners */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Shadow for depth */
  transition: box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out; /* Smooth transitions */
}

/* Heading styles */
.addons-heading {
  font-size: 2em; /* Large heading */
  margin: 0; /* Remove any default margin */
  margin-bottom: 20px; /* Adjust bottom margin for spacing */
  color: #333; /* Dark color for visibility */
  text-align: center; /* Center align heading */
  animation: fadeInDown 1s ease-out; /* Fade in animation */
  font-family: 'Helvetica', 'Arial', sans-serif;
  font-weight: 550;
}

/* Addon option styles */
.addon-option {
  display: flex; /* Use flexbox for layout */
  align-items: center; /* Center align items vertically */
  justify-content: space-between; /* Space between items */
  margin: 15px 0; /* Space between each addon */
  padding: 10px; /* Padding for better spacing */
  background-color: #ffffff; /* White background for options */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05); /* Light shadow */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition on hover */
}

.addon-option:hover {
  transform: translateY(-3px); /* Slight upward movement on hover */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); /* Darker shadow on hover */
}

/* Addon image container styles */
.addon-image-container {
  display: inline-block; /* Allows each option to be inline */
  margin-right: 20px; /* Spacing between add-on options */
  text-align: center; /* Center text */
  border: 2px solid black; /* Blue border for all options */
  border-radius: 10px; /* Rounded corners */
  padding: 20px; /* Padding inside each option */
  cursor: pointer; /* Change cursor on hover */
  transition: transform 0.3s ease, border 0.3s ease; /* Animate transform and border */
}

.addon-image-container.selected {
  transform: scale(1.1); /* Enlarge the selected add-on */
  border-color: #0056b3; /* Change border color when selected */
  background-color: rgba(173, 216, 230, 0.5); /* Light background on selection */
}

/* Image styles */
.addon-image {
  width: 60px; /* Adjust size as necessary */
  height: 60px; /* Adjust size as necessary */
   /* Border color */
  border-radius: 50%; /* Circular images */
  transition: transform 0.3s ease, border-color 0.3s ease; /* Smooth transitions */
}

.addon-image-container:hover .addon-image  {
  transform: scale(1.2); /* Scale up on hover */
  border-color: #0056b3; 
  background-color: rgba(173, 216, 230, 0.5); /* Darker border on hover */
}


/* Addon label styles */
.addon-label {
  flex: 1; /* Adjusts label's width */
  font-weight: 400; /* Bold text for labels */
  color: #555; /* Darker color for better visibility */
  transition: color 0.3s ease; /* Smooth color transition */
  font-family: 'Helvetica', 'Arial', sans-serif;
}

/* Addon description styles */
.addon-description {
  flex: 2; /* Adjusts description width */
  font-size: 0.9em; /* Font size adjustment */
  color: #666; /* Light color for description */
}

/* Addon details styles */
.addon-details {
  display: flex; /* Use flexbox for layout */
  flex-direction: column; /* Stack price, GST, and total vertically */
  flex: 2; /* Adjusts details width */
  text-align: right; /* Right align text */
}

/* Price, GST, and Total styles */
.addon-price,
.addon-gst,
.addon-total {
  margin: 0; /* Remove default margin */
}

/* Quantity controls styles */
.quantity-control {
  display: flex; /* Use flexbox for layout */
  align-items: center; /* Center align vertically */
}

.quantity-btn {
  background-color: #007bff; /* Primary button color */
  color: white; /* Button text color */
  border: none; /* Remove default border */
  border-radius: 4px; /* Rounded corners */
  padding: 10px 15px; /* Button padding */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s, transform 0.2s; /* Smooth transitions */
}

.quantity-btn:disabled {
  background-color: #ccc; /* Disabled button color */
  cursor: not-allowed; /* Change cursor on disabled */
}

.quantity-btn:hover:not(:disabled) {
  background-color: #0056b3; /* Darker blue on hover */
  transform: translateY(-1px); /* Slight upward movement on hover */
}

.quantity-display {
  margin: 0 15px; /* Margin around quantity display */
  font-size: 1.4em; /* Larger font size for display */
  font-weight: bold; /* Bold text */
}

/* Dropdown container styles */
.addon-select-container {
  display: flex; /* Use flexbox for layout */
  align-items: center; /* Center align items vertically */
  margin-top: 30px; /* Space above dropdown */
  gap: 20px; /* Space between label and dropdown */
}

/* Label styles */
.addon-select-label {
  font-size: 1.2em; /* Label font size */
  color: #333; /* Dark color */
  font-weight: bold; /* Bold labels */
}

/* Dropdown styles */
.addon-dropdown {
  padding: 12px; /* Padding inside dropdown */
  border-radius: 6px; /* Rounded corners */
  border: 1px solid #ccc; /* Border color */
  width: 100%; /* Full width */
  max-width: 280px; /* Max width for dropdown */
  cursor: pointer; /* Pointer cursor on hover */
  transition: border-color 0.3s; /* Smooth border color transition */
}

.addon-dropdown:hover {
  border-color: #007bff; /* Change border color on hover */
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.2); /* Light shadow on hover */
}

/* Animations */
@keyframes fadeInDown {
  from {
      opacity: 0; /* Start transparent */
      transform: translateY(-20px); /* Start from above */
  }
  to {
      opacity: 1; /* Fully visible */
      transform: translateY(0); /* End position */
  }
}

/* Highlight the selected add-on */
.addon-option.selected {
  background-color: #f0f8ff; /* Light blue for selected add-ons */
  border-color: #007bff; /* Blue border to indicate selection */
}

/* Additional styles */
.addon-info {
  margin-bottom: 5px; /* Space below addon info */
}

.selected-addons {
  display: flex; /* Use flexbox for layout */
  flex-direction: column; /* Stack selected addons */
  margin: 10px 0; /* Vertical margin */
}

/* Responsive styles */
@media (max-width: 768px) {
  .addons-section {
      padding: 15px; /* Reduce padding for smaller screens */
  }

  .addons-heading {
      font-size: 1.5em; /* Adjust heading size */
      margin-bottom: 15px; /* Adjust margin */
  }

  .addon-option {
      flex-direction: column; /* Stack items vertically */
      align-items: flex-start; /* Align items to the start */
      margin: 10px 0; /* Adjust margin */
  }

  .addon-label, .addon-description, .addon-details {
      flex: none; /* Reset flex for labels and descriptions */
      width: 100%;
      font-weight: 400;
      font-family: 'Helvetica', 'Arial', sans-serif; /* Full width */
  }

  .quantity-control {
      flex-direction: column; /* Stack buttons vertically */
      align-items: stretch; /* Stretch buttons to full width */
      margin-top: 10px; /* Add spacing above */
  }

  .quantity-display {
      margin: 10px 0; /* Adjust margin for mobile */
  }

  .addon-select-container {
      flex-direction: column; /* Stack label and dropdown */
      align-items: flex-start; /* Align to start */
  }

  .addon-dropdown {
      width: 100%; /* Full width on mobile */
      max-width: none; /* Remove max width */
  }

  .addon-image-container {
      margin-right: 0; /* Remove right margin */
      margin-bottom: 10px; /* Add bottom margin for spacing */
      width: 100%; /* Full width for options */
      border: 2px solid #007bff; /* Blue border for all options */
      transition: transform 0.3s ease, border 0.3s ease; /* Animate transform and border */
  }

  .addon-image {
      width: 40px; /* Adjust image size for mobile */
      height: 40px; /* Adjust image size for mobile */
      /* Border color */
      border-radius: 50%; /* Circular images */
      transition: transform 0.3s ease, border-color 0.3s ease; /* Smooth transitions */
  }

  .addon-image-container:hover .addon-image {
      transform: scale(1.1); /* Scale up on hover */
      border-color: #0056b3; /* Darker border on hover */
  }
}
.addons-section {
  margin: 20px 0;
}

.addons-heading {
  font-size: 24px;
  margin-bottom: 10px;
}

.custom-dropdown {
  position: relative;
  margin-bottom: 20px; /* Space below the dropdown */
}

.dropdown {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
  font-size: 16px;
  appearance: none; /* Remove default arrow */
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="gray" class="bi bi-caret-down" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1.5 5.5a.5.5 0 0 1 .707 0L8 10.793l5.793-5.293a.5.5 0 1 1 .707.707l-6 6a.5.5 0 0 1-.707 0l-6-6a.5.5 0 0 1 0-.707z"/></svg>'); /* Custom arrow */
  background-repeat: no-repeat;
  background-position: right 10px center; /* Position arrow */
  transition: border-color 0.3s ease; /* Smooth transition for border color */
  font-family: 'Helvetica', 'Arial', sans-serif;
}

.dropdown:focus {
  border-color: #007bff; /* Change border color on focus */
  outline: none; /* Remove default outline */
}

.selected-addons {
  margin-top: 20px;
}

.addon-option {
  display: flex;
  align-items: center; /* Center items vertically */
  justify-content: space-between; /* Space between label and remove button */
  padding: 10px;
  border: 1px solid #e0e0e0; /* Light border for the selected add-ons */
  border-radius: 4px;
  margin-bottom: 10px; /* Space between selected add-ons */
  transition: background-color 0.3s; /* Smooth background color change */
}

.addon-option:hover {
  background-color: #f0f0f0; /* Change background on hover */
}

.addon-label {
  font-weight: 500 /* Make label bold */
}

.addon-description {
  color: #666; /* Description color */
  font-size: 14px; /* Smaller font for description */
}

.remove-button{
  marginLeft: '10px'; cursor: 'pointer'; color: 'red'
}
.addon-details {
  margin-top: 5px; /* Space above details */
  font-size: 14px; /* Font size for details */
}
.addons-section {
  padding: 15px;
  background-color: #ffffff; /* White background for contrast */
  border-radius: 10px; /* Rounded corners for a modern look */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Animation for scaling and shadow */
}
.addons-heading {
  font-size: 22px; /* Larger heading font */
  color: #333; /* Dark color for contrast */
  margin-bottom: 15px;
  text-align: center; /* Center align heading */
}

.custom-dropdown {
  margin-bottom: 20px; /* Spacing below dropdown */
}

.dropdown {
  width: 100%; /* Full width for dropdown */
  padding: 12px; /* Increased padding for touch targets */
  border: 1px solid #007bff; /* Blue border for dropdown */
  border-radius: 5px; /* Rounded corners */
  font-size: 16px; /* Font size for mobile */
  transition: border-color 0.2s ease;
  font-family: 'Helvetica', 'Arial', sans-serif; /* Animation for border color */
}

.dropdown:focus {
  outline: none; /* Remove default outline */
  border-color: #0056b3; /* Darker blue on focus */
}

.selected-addons {
  margin-top: 15px; /* Margin above selected add-ons */
}

.addon-option {
  background: #f1f1f1; /* Light grey background for options */
  padding: 15px;
  border-radius: 8px; /* Rounded corners */
  margin-bottom: 15px; /* Spacing between options */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: transform 0.2s ease; /* Animation for scaling */
}

.addon-option:hover {
  transform: translateY(-2px); /* Slight lift on hover */
}

.addon-label {
  font-weight: 500; /* Bold label for emphasis */
  color: #007bff; /* Primary color for labels */
}

.addon-description {
  font-size: 14px; /* Font size for descriptions */
  color: #555; /* Dark grey for description text */
  margin-top: 5px; /* Margin above description */
}

.addon-details {
  font-size: 14px; /* Uniform size for details */
  margin-top: 10px; /* Margin above details */
}

.addon-price,
.addon-gst,
.addon-total {
  margin: 2px 0; /* Spacing between price details */
  color: #333; /* Dark color for prices */
}

button {
  background-color: transparent; /* No background */
  border: none; /* No border */
  cursor: pointer; /* Pointer cursor */
  color: #ff0000; /* Red color for remove button */
  font-size: 16px; /* Consistent font size */
  transition: color 0.2s ease; /* Animation for color change */
}

button:hover {
  color: #d40000; /* Darker red on hover */
}

/* Media queries for improved mobile responsiveness */
@media (max-width: 600px) {
  .addons-section {
    padding: 10px; /* Less padding on small screens */
  }

  .addons-heading {
    font-size: 20px; /* Smaller heading font on mobile */
  }

  .dropdown {
    font-size: 14px;
    font-family: 'Helvetica', 'Arial', sans-serif; /* Smaller font for dropdown */
  }

  .addon-option {
    font-size: 14px; /* Smaller font for addon options */
  }
}
.addons-section {
  padding: 15px;
  background-color: #ffffff; /* White background for contrast */
  border-radius: 10px; /* Rounded corners for a modern look */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Animation for scaling and shadow */
}

.addons-heading {
  font-size: 22px; /* Larger heading font */
  color: #333; /* Dark color for contrast */
  margin-bottom: 15px;
  text-align: center; /* Center align heading */
}

.custom-dropdown {
  margin-bottom: 20px; /* Spacing below dropdown */
}

.dropdown {
  width: 100%; /* Full width for dropdown */
  padding: 12px; /* Increased padding for touch targets */
  border: 1px solid #007bff; /* Blue border for dropdown */
  border-radius: 5px; /* Rounded corners */
  font-size: 16px; /* Font size for mobile */
  transition: border-color 0.2s ease; 
  font-family: 'Helvetica', 'Arial', sans-serif;/* Animation for border color */
}

.dropdown:focus {
  outline: none; /* Remove default outline */
  border-color: #0056b3; /* Darker blue on focus */
}

.selected-addons {
  margin-top: 15px; /* Margin above selected add-ons */
}

.addon-option {
  background: #f1f1f1; /* Light grey background for options */
  padding: 15px;
  border-radius: 8px; /* Rounded corners */
  margin-bottom: 15px; /* Spacing between options */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: transform 0.2s ease; /* Animation for scaling */
  display: flex; /* Flexbox layout for wider screens */
  flex-direction: row; /* Row layout for wider screens */
  align-items: center; /* Center align items */
}

.addon-option:hover {
  transform: translateY(-2px); /* Slight lift on hover */
}

.addon-label {
  font-weight: 500; /* Bold label for emphasis */
  color: linear-gradient(135deg, #1e3c72, #2a5298);
}

.addon-description {
  font-size: 14px; /* Font size for descriptions */
  color: #555; /* Dark grey for description text */
  margin-top: 5px; /* Margin above description */
}

.addon-details {
  font-size: 14px; /* Uniform size for details */
  margin-top: 10px; /* Margin above details */
}

.addon-price,
.addon-gst,
.addon-total {
  margin: 2px 0; /* Spacing between price details */
  color: #333; /* Dark color for prices */
}

button {
  background-color: transparent; /* No background */
  border: none; /* No border */
  cursor: pointer; /* Pointer cursor */
  color: #ff0000; /* Red color for remove button */
  font-size: 16px; /* Consistent font size */
  transition: color 0.2s ease; /* Animation for color change */
}

button:hover {
  color: #d40000; /* Darker red on hover */
}

/* Media queries for improved mobile responsiveness */
@media (max-width: 600px) {
  .addon-option {
    flex-direction: column; /* Stack children vertically for mobile */
    align-items: flex-start; /* Align items to the start */
  }
  .remove-button {
    position: absolute; /* Position it absolutely within the container */
    top: 10px; /* Distance from the top */
    right: 10px; /* Distance from the right */
    margin-left: 0; /* Remove left margin */
    display: block; /* Ensure it is displayed */
  }
  .remove-button:hover {
    /* If you have any hover effects, keep them or remove them */
    /* Here, we can keep the color change effect if you want */
    color: darkred; /* Change color on hover (optional) */
  }

  .addon-description {
    margin-top: 0; /* Remove margin for better alignment */
  }

  .addon-price,
  .addon-gst,
  .addon-total {
    align-self: flex-start; /* Align prices to the start */
    margin-left: 0; /* Remove any left margin */
    margin-top: 5px; /* Add some margin above price details */
  }

  .selected-addons {
    margin-top: 10px; /* Adjust margin for mobile */
  }
}
.addon-option {
  position: relative; /* Position relative for the button's absolute positioning */
  padding: 10px; /* Add some padding for space */
  border: 1px solid #ccc; /* Example border */
  margin: 10px 0; /* Margin for spacing between add-ons */
  background-color: #f9f9f9; /* Background color for the box */
}

.remove-addon-button {
  position: absolute; /* Position the button absolutely within the box */
  top: 0px; /* Move it to the top */
  right: 10px; /* Distance from the right */
  background-color: transparent; /* Make background transparent */
  border: none; /* Remove button border */
  color: red; /* Text color */
  font-size: 24px; /* Font size */
  cursor: pointer; /* Pointer cursor on hover */
  z-index: 1; /* Ensure it stays above other elements */
}


/* Mobile media query */
@media (max-width: 600px) {
  .remove-addon-button {
    font-size: 20px; /* Adjust font size for mobile if needed */
    top: 5px; /* Move it closer to the top */
    right: 5px; /* Move it closer to the right */
  }
}

/* src/components/Navbar.css */

.navbar {
  display: flex;
  align-items: center;
  background: linear-gradient(135deg, #1e3c72, #2a5298);
  padding: 10px 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 50;
  transition: background-color 0.3s ease;
}

.navbar:hover {
  background-color: #004080;
}


.navbar-title {
  flex-grow: 1; /* Allow title to take up remaining space */
  font-size: 24px;
  margin: 0; /* Remove default margin */
  color: white;
  font-family: 'Roboto', sans-serif;
  text-align: center; /* Center text on larger screens */
  font-family: 'Helvetica', 'Arial', sans-serif;
  font-weight: 600;
}

/* Responsive Design */
@media (max-width: 768px) {
  .navbar {
    flex-direction: row; /* Keep items in a row */
    padding: 10px; /* Adjust padding for mobile */
  }
  
  .navbar-title {
    font-size: 18px; /* Smaller font size for mobile */
    margin-left: 40px; /* Increased left margin for more spacing on mobile */
    text-align: left; /* Align text to the left */
    flex-grow: 0; /* Prevent title from taking up extra space */
  }

  .navbar-logo {
    margin-bottom: 0; /* Remove bottom margin */
  }

  .nav-links {
    flex-direction: column;
    width: 100%;
    background-color: #1560bd; /* Match navbar background */
    position: absolute;
    top: 60px;
    left: 0;
    display: none;
    transition: max-height 0.5s ease;
  }

  .navbar:hover .nav-links {
    display: flex; /* Show links on hover */
    max-height: 200px;
    overflow: hidden;
  }

  .menu-link {
    text-align: center; /* Center align links */
    padding: 10px 0; /* Padding around links */
    width: 100%; /* Full width links */
  }

  .menu-link:hover {
    color: #ffcc00;
    transform: scale(1.1);
  }
}
/* NavBar.css */
.navbar-logo {
  width: 71px; /* Adjust the width as needed */
  height: 50px; /* Maintain aspect ratio */
}

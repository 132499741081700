/* Modal.css */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other elements */
}

.modal-content {
  background-color: white; /* Background color of the modal */
  border-radius: 8px; /* Rounded corners */
  padding: 20px; /* Padding inside the modal */
  max-width: 400px; /* Maximum width */
  max-height: 80vh; /* Maximum height */
  overflow-y: auto; /* Enable vertical scrolling */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Slight shadow for depth */
}

.close-button {
  background-color: transparent; /* Transparent background for close button */
  border: none; /* Remove border */
  font-size: 16px; /* Font size for close button */
  cursor: pointer; /* Pointer cursor */
  margin-bottom: 10px; /* Space below close button */
}

.close-button:hover {
  color: red; /* Change color on hover */
}
/* Modal.css */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other elements */
}

.modal-content {
  background-color: white; /* Background color of the modal */
  border-radius: 8px; /* Rounded corners */
  padding: 20px; /* Padding inside the modal */
  width: 400px; /* Fixed width */
  height: 480px; /* Fixed height */
  overflow-y: auto; /* Enable vertical scrolling if content exceeds height */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Slight shadow for depth */
}

.close-button {
  background-color: transparent; /* Transparent background for close button */
  border: none; /* Remove border */
  font-size: 16px; /* Font size for close button */
  cursor: pointer; /* Pointer cursor */
  
}

.close-button:hover {
  color: red; /* Change color on hover */
}

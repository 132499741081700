/* Basic Styling */
.summary-container {
  width: 100%;
  max-width: 1900px;
  margin: 0 auto;
  padding: 0px;
  box-sizing: border-box;
}

.summary-article {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.summary-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
  color:#1e3c72,
}

.note {
  font-size: 14px;
  margin-bottom: 20px;
  color: #555;
}

.note span {
  color: #2a5298;
}

/* Table Styling */
.summary-table-container {
  overflow-x: auto;
  max-height: 400px; /* Fixed height for scrollable tables */
}

.scrollable-table {
  width: 100%;
  overflow-x: auto;
}

.summary-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.summary-table th,
.summary-table td {
  padding: 12px;
  text-align: center;
  border: 1px solid #ddd;
}

.summary-table th {
  background-color: #2a5298;
  color: #fff;
  font-weight: bold;
}

.summary-table td {
  background-color: #fff;
}

/* Add-ons Section */
h4 {
  font-size: 20px;
  font-weight: bold;
  margin-top: 20px;
}

.selected-addons-container {
  margin-top: 20px;
}

/* Total Price Styling */
.total-price {
  font-size: 18px;
  font-weight: bold;
  text-align: right;
}


.submit-button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #2a5298;
  color: white;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color:white;
}

.submit-button.disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* Checkbox and Terms Section */
.terms-conditions {
  margin-top: 20px;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.terms-conditions input {
  margin-right: 10px;
}

.terms-conditions label {
  cursor: pointer;
  color: #2a5298;
}

.terms-conditions label:hover {
  text-decoration: underline;
}

/* Modal Styling */
.Modal {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.Modal .modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 500px;
  max-width: 90%;
}

.Modal .modal-header {
  font-size: 20px;
  font-weight: bold;
}

.Modal .modal-footer {
  text-align: right;
}

.Modal button {
  background-color: #2a5298;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
}

.Modal button:hover {
  background-color: #2a5298;
}
.submit-button {
  padding: 10px 10px;
  background-color: #28a745; /* Submit button color */
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 12px;
  transition: background-color 0.3s, transform 0.2s;
}

.submit-button:hover {
  background-color:white ; /* Darker shade on hover */
}

/* Styling for the disabled state */
.submit-button.disabled {
  background-color: #d6d6d6; /* Light grey color for disabled button */
  color: #a8a8a8; /* Grey text color */
  cursor: not-allowed; /* Change cursor to indicate disabled */
}
.submit-button:hover {
  color: white; 
}
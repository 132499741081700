
.buyplan-container {
  display: flex;
  flex-direction: row; /* Default layout */
  height: calc(100vh - 60px); /* Full height of the viewport minus navbar height */
  overflow: hidden; /* Hide overflow */
  margin-top: 60px; /* Ensure the container starts below the navbar */
}

.navbar-spacing {
  height: 60px; /* Adjust height as per your navbar height */
}

.buyplan-left {
  width: 74%; /* 74% width for Buy Plan */
  padding: 20px;
  background-color: #f9f9f9; /* Light background for visibility */
  overflow-y: auto;
  margin: 0; /* Ensure no margin */
}

.heading {
  text-align: center;
  color: #333;
  font-weight: 600;
  font-family: 'Helvetica', 'Arial', sans-serif;
}

.label {
  display: block; /* Ensure label is above the input */
  margin: 10px 0 5px; /* Margin for spacing */
  font-weight: 550;
  font-family: 'Helvetica', 'Arial', sans-serif; /* Bold labels */
}

.input-text,
.date-picker,
.center-dropdown,
.input-participants,
.input-courts,
.input-time {
  width: 95%; /* Full width */
  padding: 10px; /* Padding */
  margin-bottom: 15px; /* Bottom margin */
  border: 1px solid #ccc; /* Border */
  border-radius: 4px; /* Rounded corners */
  transition: border-color 0.3s;
  font-family: 'Helvetica', 'Arial', sans-serif; /* Smooth border color transition */
}

.center-dropdown {
  width: 97%; /* Full width */
}

.input-text:focus {
  border-color: #007BFF; /* Change border color on focus */
  outline: none; /* Remove default outline */
}

.sport-selection {
  display: flex; /* Ensures the sports options are in a row */
  justify-content: space-between; /* Space between options */
  margin-bottom: 15px; /* Bottom margin */
}

.sport-option {
  display: inline-block; /* Allows each option to be inline */
  margin-right: 20px; /* Spacing between sport options */
  text-align: center; /* Center text */
  border: 2px solid transparent; /* Border for all options */
  border-radius: 10px; /* Rounded corners */
  padding: 20px; /* Padding inside each option */
  cursor: pointer; /* Change cursor on hover */
  transition: transform 0.3s ease, border 0.3s ease; 
  width: 100px;
  border-color: #333; /* Animate transform and border */
}

.sport-option.selected {
  transform: scale(1.1); /* Enlarge the selected sport */
  border-color: blue; /* Change border color when selected */
  background-color: rgba(173, 216, 230, 0.5); /* Light background on selection */
}

.sport-icon {
  width: 50px; /* Adjust as necessary */
  height: 50px; /* Adjust as necessary */
}

.button-container {
  display: flex; /* Use flexbox to align buttons */
  justify-content: space-between; /* Distribute space between buttons */
  align-items: center; /* Center align buttons vertically */
  margin-bottom: 20px; /* Space between button container and submit button */
}

.add-sport-button,
.add-date-button {
  flex: 1; /* Make buttons flexible to take equal space */
  padding: 10px 20px; /* Adjust padding for better appearance */
  font-size: 16px; /* Increase font size */
  border: none; /* Remove default border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  background-color: #007BFF; /* Primary button color */
  color: white; /* Text color */
  transition: background-color 0.3s; /* Smooth background transition */
  margin: 0 10px; /* Space between buttons */
  font-family: 'Helvetica', 'Arial', sans-serif;
}

.add-sport-button:hover,
.add-date-button:hover {
  background-color: black; /* Darker blue on hover */
}

.submit-button {
  padding: 10px 10px; /* Larger padding for the submit button */
  font-size: 18px; /* Slightly larger font size */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #28a745; /* Submit button color */
  color: white;
  transition: background-color 0.3s;
  margin-top: 10px; /* Add margin above the submit button */
}



.button-group {
  display: flex;
  justify-content: space-between; /* Align buttons across the container */
  margin: 20px 0; /* Add some vertical spacing */
}

.submit-button-container {
  display: flex;
  justify-content: center;
}

.add-ons-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.add-ons-table th,
.add-ons-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center; /* Center align for better visual appearance */
}

.add-ons-table th {
  background-color: #f2f2f2;
}

.add-ons-table button {
  margin: 0 5px; /* Add some spacing around buttons */
}

.date-selection {
  margin-bottom: 1rem; /* Add space below for better layout */
}

.date-picker {
  cursor: pointer; /* Pointer cursor */
}

.date-picker:focus {
  outline: none; /* Remove default outline */
  border-color: #66afe9; /* Change border color on focus */
  box-shadow: 0 0 5px rgba(102, 175, 233, .5); /* Add shadow effect */
}

.buyplan-form {
  margin: 0; /* Remove space between forms */
  padding: 15px; /* Padding inside the form */
  border: 1px solid #ddd; /* Border around form */
  border-radius: 8px; /* Rounded corners */
  transition: box-shadow 0.3s ease; /* Smooth shadow transition */
}

.buyplan-form:hover {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Shadow on hover */
}

.selection-type {
  display: flex;
  justify-content: center; /* Center the radio buttons */
  margin-bottom: 15px; /* Space below radio buttons */
}

.radio-label {
  margin: 0 15px; /* Space between radio buttons */
  display: flex;
  align-items: center; /* Align label and input vertically */
  cursor: pointer; /* Pointer cursor for better UX */
  transition: color 0.3s ease; /* Smooth transition for text color */
  font-size: 1.2rem; /* Increased font size for radio labels */
  font-weight: 550;
  font-family: 'Helvetica', 'Arial', sans-serif; /* Make font bold for better visibility */
}

.radio-label:hover {
  color: #007BFF; /* Change color on hover */
}

.quotation-summary {
  width: 40%; /* 30% width for Quotation Summary */
  background-color: #e9ecef; /* Light grey background */
  padding: 10px;
  overflow-y: scroll; /* Enable vertical scrolling */
  border-left: 1px solid #ccc; /* Add a left border for separation */
  margin: 0; /* Remove margin */
}

.sport-selection-container {
  max-width: 100%; /* Limit to the container's width */
  overflow: hidden; /* Hide overflow to maintain 3 sports display */
  position: relative; /* Position relative for inner elements */
}

.sport-type-heading {
  /* Center the heading */
  margin-bottom: 10px; /* Add some space below the heading */
}

.sport-selection {
  display: flex; /* Use flexbox to align items */
  transition: transform 0.5s ease; /* Smooth transform for animations */
  padding: 10px; /* Add padding around the selection area */
}

.hours-selection,.court-selection {
  display: flex;
  flex-direction: column;
 /* Center align all items */
  margin: 1rem 0; /* Add margin for spacing */
  padding: 0.5rem; /* Add padding for better spacing */
}

.label {
  font-size: 1.2rem; /* Larger font size for label */
  font-weight: 550; /* Bold text */
  margin-bottom: 0.5rem; 
  font-family: 'Helvetica', 'Arial', sans-serif;
}


.hours-button,.court-button {
  background-color: transparent; /* Transparent background */
  /* Text color */
  border: 2px solid black; /* Border around button */
  border-radius: 5px; /* Rounded button */
  padding: 0.5rem 1rem; /* Button padding */
  font-size: 12px ; /* Font size */
  margin: 0 10px; /* Margin between buttons */
  cursor: pointer; /* Pointer cursor */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition */
}

.hours-button:hover ,.court-button:hover{
  background-color: #007bff; /* Change background on hover */
  color: white; /* Change text color on hover */
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .buyplan-container {
    flex-direction: column; /* Stack components vertically on small screens */
    height: auto; /* Adjust height for vertical stacking */
  }

  .buyplan-left {
    width: 100%; /* Full width on small screens */
    padding: 10px; /* Reduced padding for smaller screens */
  }

 

  .sport-option {
    width: 100%; /* Full width for sport options */
    margin-bottom: 10px; /* Space between sport options */
  }

  .buttons-container {
    flex-direction: row; /* Stack buttons vertically */
    justify-content: center;
  }

  .hours-button,.court-button {
    width: 100%; /* Full width for buttons */
    margin: 5px 0; /* Space between buttons */
  }

  .label, .submit-button {
    font-size: 1.2rem;
    font-family: 'Helvetica', 'Arial', sans-serif; /* Increase font size for better readability */
  }
  .court-selection,.hours-selection {
    display: flex;
    flex-direction: column;
   /* Center align all items */
    margin: 1rem 0; /* Add margin for spacing */
    padding: 0rem; /* Add padding for better spacing */
  }
}
.button-container {
  display: flex;
  justify-content: center; /* Centers buttons horizontally */
  margin-top: 20px;
}

.add-sport-button,
.add-date-button {
  margin: 0 10px; /* Space between the buttons */
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;
  font-family: 'Helvetica', 'Arial', sans-serif;
}

.add-sport-button:hover,
.add-date-button:hover {
  background-color: #0056b3;
}

.submit-button-container {
  display: flex;
  justify-content: center;
}

.submit-button {
  padding: 11px 11px;
  background: linear-gradient(135deg, #1e3c72, #2a5298);
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;
}

submit-button:hover {
  background-color:white !important;
}

.submit-button.disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.container {
  display: flex; /* Use flexbox */
  flex-direction: row; /* Default direction for larger screens */
  justify-content: space-between; /* Space between items */
}


/* Media query for mobile devices */
@media (max-width: 768px) {
  .container {
    flex-direction: column; /* Stack items vertically on mobile */
  }
  
  .hours-button,
  .court-button {
    width: 50px; /* Set a specific width for mobile */
    padding: 0.2rem 0.4rem; /* Optionally reduce padding */
    font-size: 10px; /* Smaller font size */
    margin: 0 5px; /* Adjust margin between buttons */
    border: 1px solid black; /* Optionally reduce border size */
  }

 
  
  .label {
    font-size: 1.2rem; /* Font size for label */
    margin-bottom: 0.3rem;
    font-family: 'Helvetica', 'Arial', sans-serif; /* Space below the label */
  }
  
  .buttons-container {
    display: flex;
    align-items: center; /* Align buttons vertically in the center */
    justify-content: flex-start; /* Align buttons to the left */
  }
  
    

  
  /* Button hover effect */
  
  @media (max-width: 768px) {
    .hide-on-mobile {
      display: none;
    }
  }
  
  .hours-display {
    font-size: 1.5rem; /* Font size for display */
    margin: 0 0.2rem; /* Space on left and right */
  }
  
  .quotation-summary {
    width: 100%; /* Full width on mobile */
    border-left: none; /* Remove left border on mobile */
    margin-bottom: 20px; /* Add some space between items */
  }
}
.error-message {
  color: red;            /* Text color for visibility */
  font-size: 14px;      /* Slightly larger font size for emphasis */
  margin-top: 5px;      /* Space between the input field and the error message */
  margin-bottom: 10px;  /* Space below the error message */
  padding: 5px;         /* Padding for a better appearance */
  border: 1px solid red; /* Optional: red border to highlight the error */
  background-color: #ffe6e6; /* Light red background for visibility */
  border-radius: 3px; 
  width:895px;  /* Rounded corners for a softer look */
}
.label {
  justify-content: space-between; /* Space between the text and the button */
  align-items: center; /* Center align items vertically */
  padding-right: 24px;
  font-family: 'Helvetica', 'Arial', sans-serif;/* Add padding on the right side */
}

.remove-date-button {
  background-color: #ff4d4d; /* Red background color */
  color: white; /* White text color */
  border: none; /* No border */
  border-radius: 5px; /* Slightly rounded corners */
  padding: 5px 10px; /* Padding for top-bottom and left-right */
  font-size: 16px; /* Font size */
  cursor: pointer; /* Change cursor to pointer on hover */
  transition: background-color 0.3s ease; /* Smooth background color transition */
  margin-left: 10px;
  font-family: 'Helvetica', 'Arial', sans-serif; /* Space between the label text and the button */
}



.remove-date-button:focus {
  outline: none; /* Remove outline on focus */
}

.remove-date-button:active {
  background-color: #cc0000; /* Even darker red when button is clicked */
}
.sport-type-heading {
  display: flex; /* Enable flexbox layout */
  justify-content: space-between; /* Space between the text and the button */
  align-items: center; /* Center align items vertically */
  padding-right: 27px; /* Add padding on the right side */
  font-family: 'Helvetica', 'Arial', sans-serif;
  font-weight: 550;
}

.remove-sport-button {
  background-color: #ff4d4d; /* Red background color */
  color: white; /* White text color */
  border: none; /* No border */
  border-radius: 5px; /* Slightly rounded corners */
  padding: 5px 10px; /* Padding for top-bottom and left-right */
  font-size: 16px; /* Font size */
  cursor: pointer; /* Change cursor to pointer on hover */
  transition: background-color 0.3s ease; /* Smooth background color transition */
  margin-left: 10px; /* Space between the label text and the button */
}



.remove-sport-button:focus {
  outline: none; /* Remove outline on focus */
}

.remove-sport-button:active {
  background-color: #cc0000; /* Even darker red when button is clicked */
}
.quotation-summary {
  width: 40%; /* 30% width for Quotation Summary */
  background-color: #e9ecef; /* Light grey background */
  padding: 10px;
  overflow-y: auto; /* Enable vertical scrolling */
  border-left: 1px solid #ccc; /* Add a left border for separation */
  margin: 0; /* Remove margin */
  order: 1; /* Default order */
}

@media (max-width: 768px) { /* Adjust the breakpoint as needed */
  .quotation-summary {
    position: static; /* Let it follow the normal document flow */
    width: 100%; /* Ensure it still takes full width */
    margin-top: 10px; /* Add some margin to separate it from other elements */
    padding: 0px; /* Adjust padding for mobile screens */
    z-index: 1; /* Lower the z-index to avoid overlap */
    background-color: #fff; /* Keep background for visibility */
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1); /* Keep subtle shadow */
    border-top-left-radius: 10px; /* Rounded corners */
    border-top-right-radius: 10px;
  }

  .other-elements { /* Replace with your actual class for other elements */
    order: 1; /* Set other elements to order 1 */
  }

  .navbar-spacing {
    height: 0px;
  }
  .buyplan-form {
    width: 100%; /* Default width for mobile */
    max-width: 600px; /* Set a maximum width to prevent it from becoming too wide */
    margin: 0 auto; /* Center the form on the screen */
    padding: 10px; /* Adjust padding for mobile */
    border: 1px solid #ddd;
    border-radius: 8px;
    box-sizing: border-box; /* Ensure padding is included in the element's width */
    transition: box-shadow 0.3s ease;
  }

  .buyplan-left {
    width: 90%; /* Set width to 90% for mobile */
    max-width: 600px; /* Set a maximum width for larger screens */
    padding: 10px; /* Reduce padding for smaller screens */
    margin: 0 auto; /* Center the buy plan section */
    background-color: #f9f9f9; /* Keep the background color */
    overflow-y: auto; /* Keep vertical scrolling if needed */
  }
}

.add-sport-button:hover,
.add-date-button:hover,

.input-text:focus,
.date-picker:focus,
.center-dropdown:focus {
  outline: 2px solid #007BFF; /* Clear outline for focus */
}
.add-sport-button:hover,
.add-date-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
  color: white; /* Keep text color white on hover */
}



.hours-button:hover,
.court-button:hover {
  background-color: #007bff; /* Change background on hover */
  color: white; /* Keep text color white on hover */
}
.sport-option.disabled {
  opacity: 0.7; /* Make it look disabled */
  pointer-events: none; /* Prevent interaction */
  background-color: #f0f0f0; /* Grey background for disabled options */
  border-color: blue; /* Light grey border for disabled options */
  cursor: not-allowed; /* Change cursor to not-allowed */
}
@media (max-width: 768px) {
  .add-sport-button,
  .add-date-button {
    width: 100%;  /* Make buttons full width on small screens */
    font-size: 12px;  /* Smaller font size */
    padding: 8px;  /* Adjust padding for smaller screens */
  }
  .hours-display{
    font-size: 16px;
  }
}.sport-selection {
  display: flex;
  gap: 10px; /* Adjust the gap between the sports */
  justify-content: left; /* Center the sports horizontally */
  flex-wrap: wrap; /* Allow sports to wrap onto multiple rows if needed */
  width: 100%; /* Make sure it takes full available width */
}
@media (max-width: 600px) {
  .sport-selection {
    display: flex; /* Use flexbox for layout */
    flex-direction: row; /* Arrange items in a row */
    flex-wrap: nowrap; /* Prevent wrapping to a new line */
    overflow-x: auto; /* Allow horizontal scrolling if items exceed width */
    width: 100%; /* Full width for the container */
    align-items: center; /* Center items vertically */
    padding: 5px 0; /* Optional: add some vertical padding */
  }

  .sport-option {
    display: flex; /* Use flexbox for alignment */
    flex-direction: column; /* Arrange text vertically */
    justify-content: flex-end; /* Align text to the bottom */
    align-items: center; /* Center text horizontally */
    margin-right: 10px; /* Spacing between sport options */
    border: 2px solid transparent; /* Border for all options */
    border-radius: 10px; /* Rounded corners */
    padding: 20px; /* Increased padding for a larger inner area */
    cursor: pointer; /* Change cursor on hover */
    transition: transform 0.3s ease, border 0.3s ease, background-color 0.3s ease; 
    width: 150px; /* Increased fixed width for each option */
    height: 100px; /* Increased fixed height for a uniform size */
    border-color: #333; /* Border color for better visibility */
    font-size: 16px; /* Increased font size for better readability */
     /* Fade in animation */
  }

  /* Keyframes for fade-in animation */
  @keyframes fadeIn {
    from {
      opacity: 0; /* Start fully transparent */
      transform: translateY(20px); /* Start slightly below */
    }
    to {
      opacity: 1; /* End fully visible */
      transform: translateY(0); /* End at original position */
    }
  }

  /* Optional: Add hover effect */
 
  .sport-option.selected {
    transform: scale(1); /* Enlarge the selected sport */
    border-color: blue; /* Change border color when selected */
    background-color: rgba(173, 216, 230, 0.5); /* Light background on selection */
  }
  input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none; /* Hide the spin buttons */
}
}
.alert-box {
  position: fixed;
  top: 50px; /* adjust the top value to position it below the navbar */
  left: 0;
  width: 300px; /* adjust the width to your liking */
  background-color: #333;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  animation: slideIn 0.5s, slideOut 0.5s 3s;
  z-index: 1000;
}

@keyframes slideIn {
  from {
    left: -250px;
  }
  to {
    left: 0;
  }
}

@keyframes slideOut {
  from {
    left: 0;
  }
  to {
    left: -250px;
  }
}
.alert-box {
  position: fixed;
  top: 70px;
  right: 20px;
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
  padding: 10px;
  z-index: 1000; /* Ensure it appears above other elements */
  border-radius: 5px;
  transition: opacity 0.5s ease;
}

.p{
  font-family: 'Helvetica', 'Arial', sans-serif;
  font-weight: 200;
}

.dynamic-info {
    background-color: #f9f9f9; /* Light background for contrast */
    border: 1px solid #ddd; /* Light border for definition */
    border-radius: 8px; /* Rounded corners */
    padding: 15px; /* Spacing inside the box */
    margin-top: 20px; /* Space above the dynamic info section */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.dynamic-info h3 {
    margin: 0 0 10px; /* Space below the heading */
    font-size: 1.5em; /* Larger font size for the heading */
    color: #333; /* Darker text color */
}

.dynamic-info p {
    margin: 5px 0; /* Space between paragraphs */
    font-size: 1em; /* Standard font size */
    color: #555; /* Slightly lighter text color */
}

.map-container {
    margin-top: 10px; /* Space above the map */
    border: 1px solid #ccc; /* Border around the map */
    border-radius: 5px; /* Rounded corners for the map */
    overflow: hidden; /* Ensure the iframe fits within the container */
}

.map-container iframe {
    width: 100%; /* Full width of the container */
    height: 200px; /* Fixed height for the map */
    border: 0; /* Remove default border */
}
.multiple-event-message {
  margin-top: -10px; /* Space above the message */
  margin-bottom: 10px;
  font-size: 14px; /* Font size */
  color: #FF4500; /* Orange color */
  font-weight: bold; /* Bold text */
  display: flex; /* Use flexbox to align icon and text */
  align-items: center;
  font-family: 'Helvetica', 'Arial', sans-serif; /* Center items vertically */
}

.multiple-event-message::before {
  content: "🗓️"; 
  margin-right: 5px;
}
@media (max-width: 600px) {
  .multiple-event-message {
    margin-top: 6px; /* Reduced space above the message */
    font-size: 12px; /* Smaller font size for mobile */
    color: #FF4500; /* Keep the same color */
    font-weight: bold; /* Keep bold text */
    display: flex; /* Use flexbox to align icon and text */
    align-items: center; /* Center items vertically */
    font-family: 'Helvetica', 'Arial', sans-serif; /* Font family */
  }

  .multiple-event-message::before {
    content: "🗓️"; /* Add a calendar emoji */
    margin-right: 4px; /* Slightly reduced space between icon and text */
  }
}.another-quotation-container {
  display: none; /* Default to hidden */
}

/* Media query for mobile view */
@media (max-width: 768px) {
  .another-quotation-container {
    display: block; /* Show on mobile */
    margin-top: 1px; /* Optional: Add spacing */
    text-align: center;
    margin-bottom: 12px;
  }
}
.sport-time-table {
  width: 100%; /* Ensure the table takes full width */
  border-collapse: separate;
  border-spacing: 0 10px; 
  overflow-x: auto; /* Use 'auto' to show scrollbar only when needed */
  display: block; /* Ensure the container behaves as a block element */
}

/* Ensure the table itself has a minimum width */
.sport-time-table table {
  min-width: 800px; /* Set a minimum width to force horizontal scrolling */
  width: 97%; /* Ensure the table takes full width */
}

/* Optional: Style the scrollbar */
.sport-time-table::-webkit-scrollbar {
  height: 8px; /* Set scrollbar height */
}

.sport-time-table::-webkit-scrollbar-thumb {
  background-color: #888; /* Scrollbar thumb color */
  border-radius: 4px; /* Rounded corners */
}

.sport-time-table::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Scrollbar track color */
}

.table-row {
  background-color: #ffffff;
  border-radius: 12px; /* Rounded corners for rows */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  transition: transform 0.2s ease, box-shadow 0.2s ease; /* Smooth hover effect */
}


.table-cell {
  padding: 15px;
  text-align: center;
  border: none; /* Remove default borders */
  font-size: 14px;
  color: #333; 
  background-color: white;
}

/* Header Styling */
.sport-time-table thead th {
  background: linear-gradient(135deg, #1e3c72, #2a5298); /* Blue gradient */
  color: white;
  padding: 15px;
  font-size: 16px;
  text-transform: uppercase;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Shadow for header */
}

/* Dropdown Styling */
.sport-dropdown,
.time-dropdown,
.court-dropdown {
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ddd;
  background-color: #f5f7fa; /* Light blue background */
  font-size: 14px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.sport-dropdown:focus,
.time-dropdown:focus,
.court-dropdown:focus {
  border-color: #2a5298; /* Blue border on focus */
  box-shadow: 0 0 8px rgba(42, 82, 152, 0.3); /* Subtle glow effect */
}

/* Date Input Styling */
.date-input {
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ddd;
  background-color: #f5f7fa; /* Light blue background */
  font-size: 14px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.date-input:focus {
  border-color: #2a5298; /* Blue border on focus */
  box-shadow: 0 0 8px rgba(42, 82, 152, 0.3); /* Subtle glow effect */
}

/* Remove Button Styling */
.remove-row-button {
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.remove-row-button:hover {
  color: white;}
/* Add Row Button Styling */
.add-row-button {
  background: linear-gradient(135deg, #1e3c72, #2a5298); /* Blue gradient */
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  margin-top: 0px;
}
.add-row-button:hover {
  color: white;
}
.table-row:nth-child(even) {
  background-color: #f5f7fa;
}

@media (max-width: 768px) {
  .sport-time-table {
    border-spacing: 0 5px;
  }

  .table-cell {
    padding: 10px;
    font-size: 12px;
  }

  .sport-dropdown,
  .time-dropdown,
  .court-dropdown,
  .date-input {
    padding: 8px;
    font-size: 12px;
  }

  .remove-row-button {
    padding: 6px 12px;
    font-size: 12px;
  }

  .add-row-button {
    padding: 10px 20px;
    font-size: 14px;
    margin-top: 0px;
  }

  .sport-time-table thead th {
    padding: 10px;
    font-size: 14px;
  }

}
/* Custom Date Picker Styling */
.custom-date-picker {
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ddd;
  background-color: #f5f7fa; /* Light blue background */
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.custom-date-picker:focus {
  border-color: #2a5298; /* Blue border on focus */
  box-shadow: 0 0 8px rgba(42, 82, 152, 0.3); /* Subtle glow effect */
  outline: none;
}

/* Override react-datepicker styles */
.react-datepicker {
  font-family: Arial, sans-serif;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.react-datepicker__header {
  background: linear-gradient(135deg, #1e3c72, #2a5298); /* Blue gradient */
  color: white;
  border-radius: 12px 12px 0 0;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background-color: #2a5298; /* Blue background for selected date */
  color: white;
}

.react-datepicker__navigation {
  top: 10px;
}

.react-datepicker__navigation--previous {
  border-right-color: white;
}

.react-datepicker__navigation--next {
  border-left-color: white;
}
/* Make the month text white */
h2.react-datepicker__current-month {
  color: white;
}

.react-datepicker__day-name {
  color: white !important;
}

@media (max-width: 768px) {
  .custom-date-picker {
    width: 70px;
  }
}.custom-date-picker::placeholder {
  color: black;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
}

.info-icon {
  cursor: pointer;
  margin-left: 5px;
  font-size: 16px;
  color: black;
}
/* Modal Overlay */
.center-info-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
}

/* Modal Content */
.center-info-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 600px;
  width: 90%;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a subtle shadow */
  position: relative;
}

/* Modal Title */
.center-info-modal-title {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333; /* Dark gray text */
  text-align: center;
}

/* Close Button */
.center-info-modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666; /* Gray color for the close button */
  transition: color 0.3s ease;
}

/* Center List */
.center-info-modal-list {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Space between center items */
}

/* Center Item */
.center-info-modal-item {
  background-color: #f9f9f9; /* Light gray background for each center */
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for each item */
}

/* Center Name */
.center-info-modal-center-name {
  font-size: 20px;
  margin-bottom: 10px;
  color: #007bff; /* Blue color for center names */
}

/* Sports List */
.center-info-modal-sports-list {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

/* Sport Item */
.center-info-modal-sport-item {
  font-size: 16px;
  padding: 8px 0;
  border-bottom: 1px solid #eee; /* Light border between sports */
  color: #555; /* Medium gray for sport names */
}

.center-info-modal-sport-item:last-child {
  border-bottom: none; /* Remove border for the last item */
}.add-row-button, .remove-row-button {
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
}

.remove-row-button {
  background-color: #f44336; /* Red */
}